<template>
  <app-content-layout>
    <app-sidebar :items="menus" />
    <router-view />
  </app-content-layout>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description time Component Logic
   * @date 25.07.2020
   */
  import { mapGetters } from "vuex";

  export default {
    name: "times",
    data: vm => ({
      items: [
        {
          title: vm.$t("time.menu.shifts"),
          to: { name: "shifts" },
          icon: "tw-leave-calendars",
          active: true,
          visible: true,
          mobileSetting: vm.$enums.MOBILE_SETTINGS.SHIFT_DISABLED,
          access_key: "shifts"
        },
        {
          title: vm.$t("time.menu.timesheets"),
          to: { name: "timesheets" },
          icon: "tw-access-pass-plan",
          active: true,
          visible: true,
          mobileSetting: vm.$enums.MOBILE_SETTINGS.TIMESHEET_DISABLED,
          access_key: "timesheets"
        },
        {
          title: vm.$t("time.menu.overtime_requests"),
          to: { name: "overtime_request" },
          icon: "tw-leave-process",
          active: true,
          visible: true,
          mobileSetting: vm.$enums.MOBILE_SETTINGS.OVERTIME_DISABLED,
          access_key: "overtime_requests"
        },
        {
          title: vm.$t("time.menu.overtime_approval"),
          to: { name: "overtime_approval" },
          icon: "tw-active-employes",
          active: true,
          visible: true,
          mobileSetting: vm.$enums.MOBILE_SETTINGS.OVERTIME_DISABLED,
          access_key: "overtimeManager"
        },
        {
          title: vm.$t("time.menu.employee_calendar"),
          to: { name: "employee_calendar" },
          icon: "mdi-calendar",
          active: true,
          visible: true,
          access_key: "shiftManager"
        }
      ],
      menus: [],
      employee: {}
    }),
    computed: {
      ...mapGetters({
        routeDisabled: "auth/routeDisabled"
      }),
      menuItems() {
        return this.items.filter(item => !this.routeDisabled(item.mobileSetting));
      }
    },
    methods: {
      menuGenerate() {
        this.menus = this.menuItems.filter(item => {
          if (item.access_key === "overtimeManager") {
            return (item.visible = this.$token_manager.get("overtimeManager"));
          } else {
            return (item.visible = true);
          }
        });
      }
    },
    created() {
      this.menuGenerate();
    }
  };
</script>

<style scoped></style>
